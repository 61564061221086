import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/CategoryPageLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`CoreMedia Engagement Cloud`}</h1>
    <p>{`CoreMedia Engagement Cloud is part of CoreMedia Experience Platform along with `}<a parentName="p" {...{
        "href": "/how-to-guides/"
      }}>{`Content Cloud`}</a>{`. Both clouds work standalone or integrated. When working integrated, they improve your experience and augment the available functionality giving you a 360º of your customers. `}</p>
    <p>{`The following table lists the studios that include Engagement Cloud:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}></th>
          <th parentName="tr" {...{
            "align": null
          }}></th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><center><a parentName="td" {...{
                "href": "/engagement-cloud/managing-panels/"
              }}><span parentName="a" {...{
                  "className": "gatsby-resp-image-wrapper",
                  "style": {
                    "position": "relative",
                    "display": "block",
                    "marginLeft": "0",
                    "marginRight": "0",
                    "maxWidth": "938px"
                  }
                }}>{`
      `}<span parentName="span" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "71.66666666666667%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAADjElEQVQ4y1XQ+U+bBRzH8eff0sQYAxsgwiZOjnKDoSCZbtkCpKUHg9JCuQo9ONrn6tNRNnoAIhU2Nq7IsqpRM8FtNEC4GYtmMf5i8jZt3BJ/eP/6yvf7EVLRJvam6zha7uRk1cnOvTpOY/WIo2bea5HJNvjJMgbIbg9wKZ1J5LJZJMcikpvOKpL3X/mdCsJ+TM/edD1Hj+ycPRnhudbEcbgROWDh/Vb5HZJj/j/wcYdIfodE/p23iRTYFIS9mJ4MGm1gP97MyZqBPx5/hThm4gODTK45DQbIap8gu32CS2Y/ly1+cq0Bcqx+cjsCfGKTKLCJXHGoCHsRPfv39aSmG0gl9FwkrVzEG5G8Jj40KeRaAuick+g9UZp8MRq8ERp9UfS+CNcn4jSNRrnqkLlilyjqVREOZ5rZExvYn9Bz9sjA+bKJs7F6ZJeFj8wK+dYAV7tVKoemqBmeoqw/hG4gxGe9CpVDIXSDGp86JIp6JK71qQjnczZSpkpetlTwetnKgXaDQ2MJoqmNrDsquVY/rdI8rtgqjsgazvg6ffF1BmY26J/ZoC++lkGLekSKB4IIBwEfx0NtnHbqeXXXwLZex2tfC+GglyyLRH6XSLMvSkcogT2ygiO6Qk+62Aq98VUcsRXKXRrX+iRKXUGEl739nMed/LNr5+/FVk69X8LuMHNrItmW9NgBboqz2COPaQsuYNQSGEMJjHcTmCa/w6DNo3OplAzK6EY0hBdddl7NmnlzEeb0V5mDBSMX0UakcB95XSoF3YEM4l98ysRSEv+DJP6HSQIPk4jLPzC++IQaT4jSQYlKt4awXFfH5qiNn9cXSD6IsjNr4+J+I+NaL3k2NTN2Sb9K+ZBGuStIhStI5XCQardGjUej1qtRMaJSPqxQ5dUQ1sa7eRbxsBXpYnvJyd5cB0fTt3ErIxTYFQq7/XgTG2w+32VtK8XST8+Y3/yRjd9TbGzvsPLbCxr9YXQjErVjIYTTxev8Nf81f37fxsk3rexM3eJkyYRb8lBgl/ncKVM8oFA2pGa2qhhWqXIHqXKrVHuDVPvSqVT7FOomQghbc62cf3ub40Uj57M32And4njWgEf2UNijUNyfBuXM6GVDMjqXknmvwq1Q6VEyUM2oSu2Yyhf+EMIvYRNnkzc5XOzgzVMz+7PtHN5rxaN6KOxVKBmQKR1UKHsLjaSh9HXqO6huXKV+XKVBDPEvM/8Bjb6aZmEAAAAASUVORK5CYII=')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                      "srcSet": ["/static/bc4c39ff68ca38773eb35ef19848af27/e93cc/es-login.webp 300w", "/static/bc4c39ff68ca38773eb35ef19848af27/b0544/es-login.webp 600w", "/static/bc4c39ff68ca38773eb35ef19848af27/c975b/es-login.webp 938w"],
                      "sizes": "(max-width: 938px) 100vw, 938px",
                      "type": "image/webp"
                    }}></source>{`
          `}<source parentName="picture" {...{
                      "srcSet": ["/static/bc4c39ff68ca38773eb35ef19848af27/eed55/es-login.png 300w", "/static/bc4c39ff68ca38773eb35ef19848af27/7491f/es-login.png 600w", "/static/bc4c39ff68ca38773eb35ef19848af27/88cea/es-login.png 938w"],
                      "sizes": "(max-width: 938px) 100vw, 938px",
                      "type": "image/png"
                    }}></source>{`
          `}<img parentName="picture" {...{
                      "className": "gatsby-resp-image-image",
                      "src": "/static/bc4c39ff68ca38773eb35ef19848af27/88cea/es-login.png",
                      "alt": "es login",
                      "title": "es login",
                      "loading": "lazy",
                      "decoding": "async",
                      "style": {
                        "width": "100%",
                        "height": "100%",
                        "margin": "0",
                        "verticalAlign": "middle",
                        "position": "absolute",
                        "top": "0",
                        "left": "0"
                      }
                    }}></img>{`
        `}</picture>{`
    `}</span></a><br /><a parentName="td" {...{
                "href": "/engagement-cloud/managing-panels/"
              }}>{`Engagement Studio`}</a></center></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use Engagement Studio to manage campaigns, segments, and profiles.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><center><a parentName="td" {...{
                "href": "/engagement-cloud/users/"
              }}><span parentName="a" {...{
                  "className": "gatsby-resp-image-wrapper",
                  "style": {
                    "position": "relative",
                    "display": "block",
                    "marginLeft": "0",
                    "marginRight": "0",
                    "maxWidth": "938px"
                  }
                }}>{`
      `}<span parentName="span" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "71.66666666666667%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAYAAAAvxDzwAAAACXBIWXMAAAsTAAALEwEAmpwYAAADmUlEQVQ4yz3R+U+bBRjA8ffv0hhjJmzAuArKxuXaAuPYJks2YJbSUqAHox3HWlrgffu+b1vK0ZazElp2yIzKYUSNZAcjMMq5zC06Y+IvX0OJ/vD58fnmefIIW9FaXsV0HD60cLTcw3ZEx8msHtnXygdNMp8aRM4ZRDJaJTKNEpltEudNEhdMElnmM9ntZ3I6FYTdWDV7s1fZX7Zw8G0v27GrHM/o0sEP78hkGkUy/tMmct505sIps0i2RSKnw09Oh0SuVUHYiVaR+vo6Bw9u8CygZVPU8jquJyCa+MggpwezzH7yOxU01gAFXQoam0qxI0ixI0CBTSG3y09el598+2kwUk1qvoH9uZs89Wl5OVHFm3g9YyErHxtkss0iOe1+6ganaByZ5frQNA2+KRrFWRrFGSr6wuRZJQrsfjTdKsJOWE8q3MBh9BYveqrZVet5n2wm5DbzSZvMRYuUPq3UOUqNJ0qNJ4J2YJwq9wR1viglzgCFpzGHn+IeFSE13My2qZI99zV2nHqO5lp47dKjtrZwzqKmgxq7SpFDJadTJLdLIs8mkW873Uo6C92V0z53qQivPAOkTDc5ttdz1NvItqWWPUs1QY+dDItClmWErrEksW82mFl5zvz61v/iP24xt/4CrTtMUY/E5b4Awkuni5OglX+edfD346848V3jr++MRKPdZHao5FslSpxBKvpGKXUFKesNUt4Xorw/RMVAiMr7IS73Kly6J1M6EEB4bmrnJNLC+9/DHP+qkFow8HamgWDERVbn6QdHMI8lCDz6Ce/iKr7EWtpQco3hpTV8yRV0nlEu9fqpcAcRVk1GflsIsLEcZ/3hNFtzVt6M1SAGHWRbVQrtEnXeKMbRRUzjSdonl+iIPsASSdIRWcIymeCKJ0hZv0zlYBBhZVZieyXGZlLlacLFzryZo5nbDCr95NoUNA6JL6UprLEkndEE5vE4raFpbFOJM9MJdN4Q5fdltL4gwmG8jj+jtbx70sS7Ryb24q0cLhgYlN3k2RUKHCK22BLTq78Q+WGDsSdrhB5/T2z1Z2KrG2l1YpgKj4x+OISwOXGLk/ANDhbu8DZxm53JFg4mm/AqbvIcCiVOmYJukYv2IfIcQxTeHUbjEtE4hym6N8xnvSNc8SrofCpVIyGEjbE2TsKN7C+288eKkd0ZAweTzXhVN/ndCiUuOf3Fsn6VigGVSrfKFx4VrTeAzhdAP3Smakilxh/iX7IrAh2Bs+mKAAAAAElFTkSuQmCC')",
                      "backgroundSize": "cover",
                      "display": "block"
                    }
                  }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                      "srcSet": ["/static/4dcc330a38d6faf1b910fa7a152dcf68/e93cc/ccas-login.webp 300w", "/static/4dcc330a38d6faf1b910fa7a152dcf68/b0544/ccas-login.webp 600w", "/static/4dcc330a38d6faf1b910fa7a152dcf68/c975b/ccas-login.webp 938w"],
                      "sizes": "(max-width: 938px) 100vw, 938px",
                      "type": "image/webp"
                    }}></source>{`
          `}<source parentName="picture" {...{
                      "srcSet": ["/static/4dcc330a38d6faf1b910fa7a152dcf68/eed55/ccas-login.png 300w", "/static/4dcc330a38d6faf1b910fa7a152dcf68/7491f/ccas-login.png 600w", "/static/4dcc330a38d6faf1b910fa7a152dcf68/88cea/ccas-login.png 938w"],
                      "sizes": "(max-width: 938px) 100vw, 938px",
                      "type": "image/png"
                    }}></source>{`
          `}<img parentName="picture" {...{
                      "className": "gatsby-resp-image-image",
                      "src": "/static/4dcc330a38d6faf1b910fa7a152dcf68/88cea/ccas-login.png",
                      "alt": "ccas login",
                      "title": "ccas login",
                      "loading": "lazy",
                      "decoding": "async",
                      "style": {
                        "width": "100%",
                        "height": "100%",
                        "margin": "0",
                        "verticalAlign": "middle",
                        "position": "absolute",
                        "top": "0",
                        "left": "0"
                      }
                    }}></img>{`
        `}</picture>{`
    `}</span></a><br /><a parentName="td" {...{
                "href": "/engagement-cloud/users/"
              }}>{`Contact Center and Analytics Studio`}</a></center></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Use Contact Center and Analytics Studio to manage users, teams, and analytics.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      